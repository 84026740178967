<template>
  <div className="flex flex-col">
    <div>
      <Navbar/>
    </div>
    <div>
      <router-view/>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/navbar/Navbar'
export default {
  name: 'App',
  components: {
    Navbar,
  },
}
</script>
<style>

</style>
