<template>
  <div class="autocomplete-wrapper">
    <label v-if="label" class="text-white">{{ label }}</label>
    <input
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keydown.down="selectNext"
        @keydown.up="selectPrevious"
        @keydown.enter="selectSuggestionByKey"
        v-bind="$attrs"
        class="main-bg mt-2"
    />
    <ul v-if="showSuggestions" class="autocomplete-dropdown py-1 px-1">
      <li
          v-for="(suggestion, index) in filteredSuggestions"
          :key="suggestion"
          :class="{ active: index === activeIndex }"
          @click="selectSuggestion(suggestion)"
          @keydown.enter="selectSuggestion(suggestion)"
      >
        {{ suggestion }}
      </li>
    </ul>
  </div>
</template>
<script setup>
// eslint-disable-next-line no-undef
import {ref, computed, watch} from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  label: {
    type: [String, Boolean],
    default: false,
  },
  modelValue: {
    type: String,
    default: "",
  },
  valuesToCheck: {
    type: Array,
  },
  watchDataProp: {
    type: Boolean,
    default: true,
  }
});
// eslint-disable-next-line no-undef
const emit = defineEmits(['update:modelValue']);

const suggestions = ref([]);
const showSuggestions = ref(false);
const activeIndex = ref(-1);
const watchData = ref(true);



watch(() => props.modelValue, () => {
  if(props.modelValue) {
    handleInput();
  }
});

const resetWatchDataValue = () => {  watchData.value = true;}

// Autocomplete
const handleInput = () => {
  if (props.watchDataProp === true && watchData.value === true) {
    suggestions.value = props.valuesToCheck;
    showSuggestions.value = true;
    activeIndex.value = -1;
  }
}

const selectSuggestion = (suggestion) => {
  // watchData.value = false;
  emit('update:modelValue', suggestion)
  showSuggestions.value = false;
  setTimeout(resetWatchDataValue, 2000);
}

const selectSuggestionByKey = () => {
  if (activeIndex.value !== -1) {
    const suggestion = filteredSuggestions.value[activeIndex.value];
    selectSuggestion(suggestion);
    watchData.value = false;
  }
}

const selectNext = () => {
  if (activeIndex.value < filteredSuggestions.value.length - 1) {
    activeIndex.value++;
  }
}

const selectPrevious = () => {
  if (activeIndex.value > 0) {
    activeIndex.value--;
  }
}

const filteredSuggestions = computed(() => {
  return suggestions.value.filter(suggestion =>
      suggestion.toLowerCase().includes(props.modelValue.toLowerCase())
  );
})

</script>
