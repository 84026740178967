<template>
  <div class="row pt-4 px-4 flex" id="TodoMenuBar" v-if="tokenState">
    <button @click="isOpenCreate = true" type="button" class="todo-nav-button mr-2">
      <!--<PlusIcon class="h-6 w-6 text-white"/>-->
      <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24"
           height="32" width="32" xmlns="http://www.w3.org/2000/svg" style="filter: invert(100%) sepia(0%)
        saturate(7463%) hue-rotate(99deg) brightness(106%) contrast(100%);">
        <path
            d="m11 11h-7.25c-.414 0-.75.336-.75.75s.336.75.75.75h7.25v7.25c0 .414.336.75.75.75s.75-.336.75-.75v-7.25h7.25c.414
            0 .75-.336.75-.75s-.336-.75-.75-.75h-7.25v-7.25c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
            fill-rule="nonzero"/>
      </svg>
    </button>
    <form>
      <div class="flex">
        <div class="mr-2">
          <select class="mr-2 secondary-bg text-white full-width-form-field" v-model="status_filter">
            <option value="false">Offen</option>
            <option value="true">Erledigt</option>
            <option value="alle">Alle</option>
          </select>
          <select class="mr-2 secondary-bg text-white full-width-form-field" v-model="recurring_filter">
            <option></option>
            <option v-for="recurring_string in allowed_recurring_string" :key="recurring_string">{{
                recurring_string
              }}
            </option>
          </select>
        </div>
        <button @click="getTodoData" type="button" class="todo-nav-button">
          Suche
        </button>
      </div>
    </form>
  </div>

  <MyModal :open="isOpenCreate" @close="isOpenCreate = !isOpenCreate" title="Aufgabe erstellen">
    <template v-slot:main>
      <InputFields v-model="content" type="text" placeholder="Aufgabe" class="input-fields" label="Aufgabe"/>
      <InputFields v-model="dueDate" type="date" class="input-fields" label="Fälligskeitsdatum"/>
      <AutoComplete v-model="recurringString" :inputValue="recurringString" type="text"
                    placeholder="Wiederkehrend (Formel)" :valuesToCheck="allowed_recurring_string"
                    class="input-fields" label="Wiederkehrend (Formel)" @input="$emit('update:recurringString')"/>
      <div class="input-field-wrapper p-2.5">
        <div class="input-checkbox-wrapper">
          <label class="text-white">Gemeinsame Aufgabe</label>
          <input
              type="checkbox"
              class="main-bg mt-2"
              v-model="shared"
          />
        </div>
      </div>


    </template>
    <template v-slot:footer>
      <button @click="createTodo" class="todo-create-button">Aufgabe erstellen</button>
    </template>
  </MyModal>

  <MyModal :open="isOpenEdit" @close="isOpenEdit = !isOpenEdit" title="Aufgabe bearbeiten">
    <template v-slot:main>
      <InputFields v-model="id" type="text" placeholder="ID" class="input-fields" label="ID"/>
      <InputFields v-model="content" type="text" placeholder="Aufgabe" class="input-fields" label="Aufgabe"/>
      <InputFields v-model="dueDate" type="date" class="input-fields" label="Fälligskeitsdatum"/>
      <AutoComplete v-model="recurringString" type="text" placeholder="Wiederkehrend (Formel)"
                    :valuesToCheck="allowed_recurring_string"
                    :watchDataProp="watchDataProp" class="input-fields" label="Wiederkehrend (Formel)"/>
      <div class="input-field-wrapper p-2.5">
        <div class="input-checkbox-wrapper">
          <label class="text-white">Gemeinsame Aufgabe</label>
          <input
              type="checkbox"
              class="main-bg mt-2"
              v-model="shared"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button @click="editTodo" class="todo-edit-button">Aufgabe bearbeiten</button>
    </template>
  </MyModal>

  <div class="gap-4 px-4 py-6" v-if="tokenState">
    <table>
      <thead>
      <tr>
        <th class="px-4 py-2" scope="col">
          <div class="float-center inline-flex flex-row items-center">
            <!--<DocumentTextIcon class="h-6 w-6 text-gray-400 float-left mr-2"/>-->
            <svg clip-rule="evenodd" fill-rule="evenodd" height="32" width="32" style="float: left; margin-right: .5rem;
            filter: invert(100%) sepia(0%) saturate(7463%) hue-rotate(99deg) brightness(106%) contrast(100%);"
                 stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="m21 4.009c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379
                  1-1zm-16.5.5h15v15h-15zm2.449 7.882 3.851 3.43c.142.128.321.19.499.19.202 0
                  .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204
                  0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0
                  .205.084.409.249.557z"
                  fill-rule="nonzero"/>
            </svg>
            Aufgabe
          </div>
        </th>
        <th class="px-4 py-2" scope="col">
          <div class="float-center inline-flex flex-row items-center">
            <!--<CalendarDaysIcon class="h-6 w-6 text-gray-400 float-left mr-2"/>-->
            Fälligkeitsdatum
          </div>
        </th>
        <th class="px-4 py-2" scope="col">
          <div class="float-center inline-flex flex-row items-center">
            <!--<ArrowPathIcon class="h-6 w-6 text-gray-400 float-left mr-2"/>-->
            Wiederkehrend
          </div>
        </th>
        <th class="px-4 py-2" scope="col">
          <div class="float-center inline-flex flex-row items-center">
            <!--<ArrowPathIcon class="h-6 w-6 text-gray-400 float-left mr-2"/>-->
            Gemeinsame Aufgabe
          </div>
        </th>
        <th class="px-4 py-2" scope="col">
          <div class="float-center inline-flex flex-row items-center">
            <!--<Square3Stack3DIcon class="h-6 w-6 text-gray-400 float-left mr-2"/>-->
            Aktionen
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="id in todo" :key="id" class="justify-self-center w-full"
          v-bind:class="formatColorOnDueDate(id.due, id.done)">
        <td data-label="Aufgabe">{{ id.content }}</td>
        <td data-label="Fälligkeitsdatum">
          {{ dateFormatter(id.due) }}
        </td>
        <td data-label="Wiederkehrend">
          <div v-if="id.recurring_string">{{ id.recurring_string }}</div>
          <div v-else>Einmalig</div>
        </td>
        <td data-label="Gemeinsame Aufgabe">
          <input type="checkbox" v-model="id.shared" disabled="disabled"/>
        </td>
        <td>
          <div class="flex justify-center">
            <button
                @click="openEditTodoModal(id.id, id.content, id.due, id.recurring, id.recurring_string, id.shared)"
                class="px-1.5 py-0.5 mr-2 mt-2">
              <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                   style="filter: invert(100%) sepia(0%) saturate(7463%) hue-rotate(99deg) brightness(106%) contrast(100%);">
                <path
                    d="M8.071 21.586l-7.071 1.414 1.414-7.071 14.929-14.929 5.657 5.657-14.929
                    14.929zm-.493-.921l-4.243-4.243-1.06 5.303 5.303-1.06zm9.765-18.251l-13.3 13.301
                    4.242 4.242 13.301-13.3-4.243-4.243z"/>
              </svg>
              <!--<PencilSquareIcon class="h-6 w-6 text-white"/>-->
            </button>
            <button @click="deleteTodo(id.id)" class="px-1.5 py-0.5 mr-2 mt-2">
              <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                   style="filter: invert(32%) sepia(52%) saturate(2162%) hue-rotate(324deg) brightness(94%) contrast(97%);">
                <path
                    d="M9 3h6v-1.75c0-.066-.026-.13-.073-.177-.047-.047-.111-.073-.177-.073h-5.5c-.066
                    0-.13.026-.177.073-.047.047-.073.111-.073.177v1.75zm11 1h-16v18c0 .552.448 1 1 1h14c.552
                    0 1-.448 1-1v-18zm-10 3.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5v12c0 .276.224.5.5.5s.5-.224.5-.5v-12zm5
                    0c0-.276-.224-.5-.5-.5s-.5.224-.5.5v12c0 .276.224.5.5.5s.5-.224.5-.5v-12zm8-4.5v1h-2v18c0
                    1.105-.895 2-2 2h-14c-1.105 0-2-.895-2-2v-18h-2v-1h7v-2c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v2h7z"/>
              </svg>
              <!--<TrashIcon class="h-6 w-6 text-red-700"/>-->
            </button>
            <button
                @click="resolveTodo(id.id, id.content, id.due, id.recurring, id.recurring_string, id.shared)"
                class="px-1.5 py-0.5 mr-2 mt-2">
              <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                   style="filter: invert(30%) sepia(88%) saturate(2613%) hue-rotate(135deg) brightness(87%) contrast(96%);">
                <path d="M24 4.685l-16.327 17.315-7.673-9.054.761-.648 6.95 8.203 15.561-16.501.728.685z"/>
              </svg>
              <!--<CheckCircleIcon class="h-6 w-6 text-green-600"/>-->
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="gap-4 px-4 py-6" v-else>
    <p class="text-4xl text-center">Bitte logge dich ein, um deine Aufgaben zu sehen.</p>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import moment from "moment";
import {inject} from 'vue';
import {deleteApiData, getApiData, postApiData} from "@/modules/fastapiData";
import AutoComplete from "@/components/helper/AutoComplete.vue";
import MyModal from "@/components/helper/MyModal.vue";
import InputFields from "@/components/helper/InputFields.vue";

const $cookies = inject('$cookies');
$cookies.config('1d', '/', '', true, 'LAX')

const token = $cookies.get("token")

const tokenState = ref(false)
const checkTokenState = () => {
  const expiresDateUnformatted = window.$cookies.get("expiresDate")
  if(window.$cookies.get("expiresDate") === null) {
    tokenState.value = false
  } else {
    const expiresDate = expiresDateUnformatted.replace("%3A", ":")
    const expiresDateFormatted = moment(expiresDate).unix()
    const now = moment(Date.now()).unix()

    tokenState.value = expiresDateFormatted >= now;
  }
}

const status_filter = ref("false");
const recurring_filter = ref("");

const todo = ref([]);
const loading = ref(true);
const id = ref("");
const content = ref("");
const dueDate = ref("");
// const user = ref("Ich");
const recurring = ref(0);
const recurringString = ref("");
const shared = ref(false);
const done = ref(false);
const isOpenCreate = ref(false);
const isOpenEdit = ref(false);
const watchDataProp = ref(true);

const allowed_recurring_string = [
  "Täglich", "Jeden zweiten Tag", "Jeden dritten Tag", "Jeden vierten Tag", "Jeden fünften Tag", "Jeden sechsten Tag",
  "Jeden Montag", "Jeden Dienstag", "Jeden Mittwoch", "Jeden Donnerstag", "Jeden Freitag", "Jeden Samstag",
  "Jeden Sonntag", "Jeden Werktag", "Wöchentlich", "Monatlich", "Jährlich", "Jeden ersten Tag", "Jeden letzten Tag"
]

// Get the data from the API
const getTodoData = async () => {
  let result;
  let url = "todos/filter";

  if (status_filter.value !== "alle") {
    url += "?done=" + status_filter.value;
    if (recurring_filter.value !== "") {
      url += "&recurring_string=" + recurring_filter.value;
    }
  }

  try {
    result = await getApiData(url, token)
    console.log(result)
    todo.value = result.sort((a, b) => {
      return new Date(a.due) - new Date(b.due);
    });
  } catch (e) {
    console.log(e)
  }

  loading.value = false;
};

// Create, edit and delete data
const createTodo = async () => {
  // recurring.value = recurringString.value !== "";
  console.log(shared.value)
  const data = {
    content: content.value,
    due: dueDate.value,
    recurring: recurring.value,
    recurring_string: recurringString.value,
    shared: shared.value,
    done: done.value
  };
  await postApiData("todo", token, data);
  await getTodoData();
  isOpenCreate.value = false
  await resetInitialValues()
};

const editTodo = async () => {
  const data = {
    id: id.value,
    content: content.value,
    due: dueDate.value,
    recurring: recurring.value,
    recurring_string: recurringString.value,
    shared: shared.value,
    done: done.value
  };

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  const raw = JSON.stringify(data);
  const requestOptions = {
    method: "PUT",
    headers: headers,
    body: raw,
    redirect: "follow"
  }

  const url = process.env.VUE_APP_FAST_API_URL + "todo/" + id.value
  await fetch(url, requestOptions);

  await getTodoData();
  isOpenEdit.value = false
  await resetInitialValues()
};

const deleteTodo = async (id) => {
  await deleteApiData("todo/" + id, token);
  await getTodoData();
};

const resolveTodo = async (id_id, id_content, id_due, id_recurring, id_recurring_string, id_shared) => {
  shared.value = shared.value === "true";
  const data = {
    id: id_id,
    content: id_content,
    due: id_due,
    recurring: recurring.value,
    recurring_string: id_recurring_string,
    shared: id_shared,
    done: true
  };

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  const raw = JSON.stringify(data);
  const requestOptions = {
    method: "PUT",
    headers: headers,
    body: raw,
    redirect: "follow"
  }

  console.log(raw)

  const url = process.env.VUE_APP_FAST_API_URL + "todo/" + id_id
  await fetch(url, requestOptions);

  await getTodoData();
};

onMounted(() => {
  getTodoData()
  checkTokenState()
});

// Helper functions
// Modal dialog
function showModal() {
  // isShowModal.value = true
  isOpenEdit.value = true
  setTimeout(() => {
    watchDataProp.value = true;
  }, 3000);
}

const openEditTodoModal = (id_id, id_content, id_due, id_recurring, id_recurring_string, id_shared) => {
  id.value = id_id;
  content.value = id_content;
  dueDate.value = id_due;
  recurring.value = id_recurring === 1;
  recurringString.value = id_recurring_string;
  shared.value = id_shared;

  watchDataProp.value = false;

  showModal()
}

// Format date
const dateFormatter = (date) => {
  return moment(date).format("DD.MM.YYYY");
}


const formatColorOnDueDate = (dueDate, done) => {
  let today = new Date();
  today.setHours(2, 0, 0, 0);
  today = today.valueOf();
  const due = new Date(dueDate).valueOf();
  if (done) {
    return 'due-date-resolved';
  } else {
    if (due < today) {
      return 'due-date-overdue';
    } else if (due === today) {
      return 'due-date-today';
    } else if (due === today + 1) {
      return 'due-date-tomorrow';
    } else {
      return 'due-date-normal';
    }
  }
};

const resetInitialValues = async () => {
  id.value = "";
  content.value = "";
  dueDate.value = "";
  recurring.value = false;
  recurringString.value = "";
  // window.location.reload();
};

</script>