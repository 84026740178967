export async function getApiData(path, token) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    }

    const url = process.env.VUE_APP_FAST_API_URL + path
    const response = await fetch(url, requestOptions);
    return await response.json()
}

export async function postApiData(path, token, body) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }

    const url = process.env.VUE_APP_FAST_API_URL + path
    const response = await fetch(url, requestOptions);
    return response.json()
}

export async function putApiData(path, token, data) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + token);
    const raw = JSON.stringify(data);
    const requestOptions = {
        method: "PUT",
        headers: headers,
        body: raw,
        redirect: "follow"
    }

    const url = process.env.VUE_APP_FAST_API_URL + path
    const response = await fetch(url, requestOptions);
    return response.json()
}

export async function deleteApiData(path, token) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    }

    const url = process.env.VUE_APP_FAST_API_URL + path
    const response = await fetch(url, requestOptions);
    return response.json()
}