import "./assets/styles/style.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


const app = createApp(App)

app.use(store).use(router).mount('#app')

app.use(VueCookies, {
    expireTimes: "365d",
    path: "/",
    secure: true,
})


const toastOptions = {
    position: "top-right",
    closeOnClick: true,
    draggable: false,
    draggablePercent: 0.6,
    hideProgressBar: false
};
app.use(Toast, toastOptions);
