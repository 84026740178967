<template>
  <div class="secondary-bg text-gray-100 py-3.5 px-6 shadow md:flex justify-between items-center">
    <a class="flex items-center cursor-pointer" href="/">
      <span class="text-green-500 text-xl mr-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" style="filter: invert(100%) sepia(0%)
        saturate(7463%) hue-rotate(99deg) brightness(106%) contrast(100%);" viewBox="0 0 24 24"><path
            d="M22 2v22h-20v-22h3c1.23 0 2.181-1.084 3-2h8c.82.916 1.771 2 3 2h3zm-11 1c0 .552.448 1 1 1 .553
            0 1-.448 1-1s-.447-1-1-1c-.552 0-1 .448-1 1zm9 1h-4l-2 2h-3.897l-2.103-2h-4v18h16v-18zm-13 9.729l.855-.791c1
            .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64c-2.141 1.869-3.709 3.949-5.967
            7.999-1.393-1.64-2.322-2.686-4.033-4.271z"/>
        </svg>
      </span>
      <h1 class="text-xl">Todo-Liste</h1>
    </a>

    <span @click="MenuOpen()" class="absolute md:hidden right-6 top-1.5 cursor-pointer text-4xl py-2">
      <svg clip-rule="evenodd" fill-rule="evenodd" width="32" height="32" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg" style="filter: invert(100%) sepia(0%)
        saturate(7463%) hue-rotate(99deg) brightness(106%) contrast(100%);" :class="open ? 'hidden' : 'visible' "><path
          d="m22 16.75c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0
          .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0
          .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0
          .75-.336.75-.75z"
          fill-rule="nonzero"/>
    </svg>
    <svg clip-rule="evenodd" fill-rule="evenodd" width="32" height="32" stroke-linejoin="round" stroke-miterlimit="2"
         viewBox="0 0 24 24" style="filter: invert(100%) sepia(0%)
        saturate(7463%) hue-rotate(99deg) brightness(106%) contrast(100%);" :class="open ? 'visible' : 'hidden' "
         xmlns="http://www.w3.org/2000/svg"><path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0
         .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0
         .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401
         0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532
         0-.425.346-.749.75-.749.192 0 .385.073.531.219z"/>
    </svg>

    </span>

    <ul class="md:flex md:items-center md:px-0 px-10 md:pb-0 pb-10 md:static absolute secondary-bg md:w-auto w-full
    top-14 duration-700 ease-in" :class="[open ? 'left-0' : 'left-[-100%]']">
      <li class="md:mx-4 md:my-0 my-6" v-for="link in Links" :key="link.name">
        <a :href="link.link" class="text-xl hover:text-green-500">{{ link.name }}</a>
      </li>
      <li class="md:mx-4 md:my-0 my-6" v-if="tokenState">
        <router-link to="/logout" class="text-xl hover:text-green-500">Logout</router-link>
      </li>
      <li class="md:mx-4 md:my-0 my-6" v-else>
        <router-link to="/register" class="text-xl hover:text-green-500 px-6">Registrieren</router-link>
        <router-link to="/login" class="text-xl hover:text-green-500">Login</router-link>
      </li>
    </ul>
  </div>
</template>
<script setup>
import {ref,onMounted} from "vue";
import moment from "moment/moment";

const tokenState = ref(false)
const checkTokenState = () => {
  const expiresDateUnformatted = window.$cookies.get("expiresDate")
  if(window.$cookies.get("expiresDate") === null) {
    tokenState.value = false
  } else {
    const expiresDate = expiresDateUnformatted.replace("%3A", ":")
    const expiresDateFormatted = moment(expiresDate).unix()
    const now = moment(Date.now()).unix()

    tokenState.value = expiresDateFormatted >= now;
  }
}

onMounted(() => {
  checkTokenState()
})


const open = ref(false);
const Links = [

];

const MenuOpen = () => {
  open.value = !open.value;
};

</script>